import { useMutation, useQuery } from "@tanstack/react-query"
import { apiUrls } from "../constants"
import { getRequest, postRequest, deleteRequestById, putRequest, postRequestWithFile, axiosInstance } from "../helpers/api"

export const categoryService = {
    getAllCategory: (key, options) => {
        return useQuery([key], () => {
            return getRequest(apiUrls.getAllCategory)
        },{
            refetchOnWindowFocus: false,
            retry: false,
            ...options
        })
    },
    getSubCategoryByCategory: (key, category, options) => {
        return useQuery([key, category], () => {
            return getRequest(apiUrls.getSubCategoryByCategory + category)
        },{
            refetchOnWindowFocus: false,
            retry: false,
            enabled: category != "",
            ...options
        })
    },
}
