import React from "react";
import SectionTitle from "../common/SectionTitle";

const PromoTwo = () => {
  return (
    <>
      <section className="promo-section ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <SectionTitle
                title="Why Choose Us"
                description="Trusted by more than 10,000 users worldwide"
                centerAlign
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                <div className="promo-icon mb-32">
                  <i className="fas fa-wifi text-primary fa-3x"></i>
                </div>
                <div className="promo-info">
                  <h3 className="h5">QUALITY POLICY</h3>
                  <p className="mb-0">
                    We provide quality goods and services to all of our
                    customers in order to ensure that we can consistently
                    provide the highest level of customer satisfaction.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                <div className="promo-icon mb-32">
                  <i className="fas fa-user-friends text-success fa-3x"></i>
                </div>
                <div className="promo-info">
                  <h3 className="h5">BUSINESS SOLUTIONS</h3>
                  <p className="mb-0">
                    We know efficiency is a magic word in business. That's why
                    we create comprehensive products with consistent top-level
                    performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4">
                <div className="promo-icon mb-32">
                  <i className="fas fa-bezier-curve text-danger fa-3x"></i>
                </div>
                <div className="promo-info">
                  <h3 className="h5">OUR VISION</h3>
                  <p className="mb-0">
                    Eagle Air Conditioning service sets the standard for the
                    HVAC service industry. Our focus is to consistently provide
                    exceptional service with an uncompromising commitment to
                    customer satisfaction.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
          {/* <div className="customer-section pt-60">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-12">
                  <div className="customer-logos-grid text-center">
                    <img
                      src="assets/img/clients/client-logo-1.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-2.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-3.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-4.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-5.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-6.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-7.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                    <img
                      src="assets/img/clients/client-logo-8.svg"
                      width="150"
                      alt="clients logo"
                      className="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                    />
                  </div>
                  <p className="text-center mt-5 mb-0 h6">
                    Trusted More than 25,00+ Companies Around the World
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default PromoTwo;
