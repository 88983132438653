import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../src/assets/logo-white.png";
import { userService } from "../../services/userService";
import { Button } from "antd";
import Swal from "sweetalert2";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  const [email, setEmail] = useState("");

  const { mutate: addEmail, isLoading } = userService.addEmail({
    onSuccess: (response) => {
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
        setEmail("");
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message,
        });
      }
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        text: error.message,
      });
    },
  });

  const handleSubmit = () => {
    const payload = {
      email: email,
    };
    addEmail(payload);
  };

  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-120`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4">
                    <img
                      style={{
                        height: 70,
                      }}
                      src={Logo}
                      alt="logo"
                      className="img-fluid logo-white"
                    />
                    <img
                      style={{
                        height: 70,
                      }}
                      src={Logo}
                      alt="logo"
                      className="img-fluid logo-color"
                    />
                  </div>
                  <p>
                    Our latest news, articles, and resources, we will sent to
                    your inbox weekly.
                  </p>

                  <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                    <input
                      type="email"
                      className="input-newsletter form-control me-2"
                      placeholder="Enter your email"
                      name="email"
                      required=""
                      autoComplete="off"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      disabled={isLoading}
                    />
                    <Button
                      disabled={isLoading}
                      type="button"
                      value="Subscribe"
                      data-wait="Please wait..."
                      className="btn btn-primary mt-3 mt-lg-0 mt-md-0"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </form>
                  <div className="ratting-wrap mt-4">
                    <h6 className="mb-0">10/10 Overall rating</h6>
                    <ul className="list-unstyled rating-list list-inline mb-0">
                      <li className="list-inline-item">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Primary Pages</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us" className="text-decoration-none">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/brands" className="text-decoration-none">
                            Brands
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/categories"
                            className="text-decoration-none"
                          >
                            Categories
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop" className="text-decoration-none">
                            Shop
                          </Link>
                        </li>
                        <li>
                          <Link to="/Contact" className="text-decoration-none">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Pages</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="" className="text-decoration-none">
                            Terms Of Services
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="text-decoration-none">
                            Return & Exchange
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="text-decoration-none">
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="" className="text-decoration-none">
                            Shipping Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Offices</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <p>
                            Dubai Office
                            <br />
                            <i class="fa-solid fa-location-dot"></i> Shop No 3,
                            Ali Rashed Ahmed Lootah Building, Deira, Dubai, UAE
                            <br />
                            {/* <i class="fa-solid fa-phone"></i> 05862984646 */}
                          </p>
                        </li>
                        <li>
                          <p>
                            Kuwait Office
                            <br />
                            <i class="fa-solid fa-location-dot"></i> Shop No 12,
                            Al Fouzan Mall, Shuwaikh Industrial Area
                            <br />
                            {/* <i class="fa-solid fa-phone"></i> 05862984646 */}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-7 col-lg-7">
                <div className="copyright-text">
                  <p className="mb-lg-0 mb-md-0">
                    &copy; Eagle Air Conditioning{" "}
                    {/* <Link
                      to="/https://themetags.com/"
                      className="text-decoration-none ms-2"
                    >
                      ThemeTags
                    </Link> */}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="footer-single-col text-start text-lg-end text-md-end">
                  <ul className="list-unstyled list-inline footer-social-list mb-0">
                    <li className="list-inline-item">
                      <Link
                        onClick={() => {
                          window.location.href =
                            "https://www.instagram.com/eaglehvac";
                        }}
                        to=""
                      >
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    {/* <li className="list-inline-item">
                      <Link to="/#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/#">
                        <i className="fab fa-dribbble"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
