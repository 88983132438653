import React from "react";
import SectionTitle from "../common/SectionTitle";

const Promo = ({ bgWhite }) => {
  return (
    <>
      <section
        className={`promo-section ptb-120 ${
          bgWhite ? "bg-white" : "bg-light"
        } `}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-6">
              <SectionTitle
                title="Our Customers Get Results"
                description="Some Description Here"
                centerAlign
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
              <div
                className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="promo-card-info mb-4">
                  <h3 className="display-5 fw-bold mb-4 text-white">
                    <i className="far fa-fingerprint text-warning me-2"></i> 10x
                  </h3>
                  <p className="text-white">Description Here </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
              <div
                className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="promo-card-info mb-4">
                  <h3 className="display-5 fw-bold mb-4 text-white">
                    <i className="far fa-paper-plane text-warning me-2"></i> 5k
                  </h3>
                  <p className="text-white">Description Here </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
              <div
                className="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="promo-card-info mb-4">
                  <h3 className="display-5 fw-bold mb-4 text-white">
                    <i className="far fa-chart-pie-alt text-warning me-2"></i>
                    95%
                  </h3>
                  <p className="text-white">Description Here </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
