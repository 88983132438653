import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import CtaTwo from '../../components/cta/CtaTwo';
import FeatureTwo from '../../components/features/FeatureTwo';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Services = () => {
  return (
    <Layout>
      <Navbar />
      <PageHeader
        title="Browse Our Comprehensive Selection of HVACR Parts"
        desc="Find Everything You Need to Keep Your System Running Smoothly"
      />
      <FeatureTwo />
      {/* <TestimonialTwo bgWhite /> */}
      {/* <CtaTwo /> */}
      <FooterOne  />
    </Layout>
  );
};

export default Services;
