import { useMutation, useQuery } from "@tanstack/react-query"
import { apiUrls } from "../constants"
import { getRequest, postRequest, deleteRequestById, putRequest, postRequestWithFile, axiosInstance } from "../helpers/api"

export const authService = {
    login: (options) => {
        return useMutation((payload) => {
            return postRequest(apiUrls.login, payload)
        }, options)
    },
    register: (options) => {
        return useMutation((payload) => {
            return postRequest(apiUrls.register, payload)
        }, options)
    },
    forgotPassword: (options) => {
        return useMutation((payload) => {
            return postRequest(apiUrls.forgotPassword, payload)
        }, options)
    },
    reSendEmail: (options) => {
        return useMutation((payload) => {
            return postRequest(apiUrls.resendEmail, payload)
        }, options)
    },
    resetPassword: (options) => {
        return useMutation((payload) => {
            return postRequest(apiUrls.resetPassword, payload)
        }, options)
    },
}


