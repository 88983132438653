import { useMutation, useQuery } from "@tanstack/react-query"
import { apiUrls } from "../constants"
import { getRequest, postRequest, deleteRequestById, putRequest, postRequestWithFile, axiosInstance } from "../helpers/api"

export const brandService = {
    getAllBrands: (key, options) => {
        return useQuery([key], () => {
            return getRequest(apiUrls.getAllBrands)
        },{
            refetchOnWindowFocus: false,
            retry: false,
            ...options
        })
    },
}
