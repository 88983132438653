import React, { useEffect, useState } from 'react'
import Routers from './routers'

// Swiper CSS
import "swiper/css";
import 'swiper/css/navigation';

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";
import { postRequest } from './helpers/api';
import { apiUrls } from './constants';
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, handleLogout } from './redux/authSlice';
import { Spin } from 'antd';

function App() {

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const checkAuth = async () => {
    const token = localStorage.getItem("token");
    if (token != "" && token != null) {
      try {
        const response = await postRequest(apiUrls.signInUser, {
          firebaseToken: token,
        });
        if (response.data.success == true) {
          const payload = {
            user: response.data.result,
            login: true,
          };
          dispatch(handleLogin(payload));
          setLoading(false);
        } else {
          dispatch(handleLogout());
          setLoading(false);
        }
      } catch (error) {
        dispatch(handleLogout());
        setLoading(false);
      }
    } else {
      dispatch(handleLogout());
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <>
    {
      loading ?
      <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", background: "FFF2F9" }}
        >
          <div className="text-center">
            <img
              style={{ height: 100 }}
              className="img-fluid"
              src={"/assets/img/logo-color.png"}
            />
            <br />
            <Spin
              style={{
                marginTop: 20,
                height: 30,
                width: 30,
                color: "#FA4EAB",
              }}
            />
          </div>
        </div>
      :
      <Routers />
    }
      
    </>
  )
}

export default App