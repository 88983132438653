import React from "react";
import { Link } from "react-router-dom";
import { authService } from "../../services/authService";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";

const PasswordResetScreen = () => {
  //Api call for forget  password
  const { mutate: forgotPassword, isLoading } = authService.forgotPassword({
    onSuccess: (response) => {
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
        formik.handleReset();
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message,
        });
      }
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        text: error.message,
      });
    },
  });

  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const payload = {
        email: values.email,
      };

      forgotPassword(payload);
    },
  });

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <Link to="/" className="mb-4 d-block text-center">
                <img
                  style={{
                    height: 80,
                  }}
                  src="assets/img/logo-white.png"
                  alt="logo"
                  className="img-fluid"
                />
              </Link>
              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="fw-bold h3">Forgot your Password?</h1>
                <p className="text-muted">
                  Don't worry. Type your email and we will send a password
                  recovery link to your email..
                </p>
                <form action="#" className="mt-5 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className=" mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          disabled={isLoading}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <small className="text-danger">
                            {formik.errors.email}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary mt-3 d-block w-100"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        disabled={isLoading}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                  <p className="font-monospace fw-medium text-center mt-3 pt-4 mb-0">
                    <Link
                      style={{
                        color: "var(--bs-primary)",
                      }}
                      to="/login"
                      className="text-decoration-none"
                    >
                      Back to login page
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordResetScreen;
