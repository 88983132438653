import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SectionTitle from "../common/SectionTitle";
import { brandService } from "../../services/brandService";
import { Empty, Skeleton } from "antd";

const IntegrationTwo = () => {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  const { isFetching: gettingBrands } = brandService.getAllBrands(
    "get-all-brands",
    {
      onSuccess: (response) => {
        if (response.data.success) {
          setBrands(response.data.result);
        } else {
          setBrands([]);
        }
      },
      onError: (error) => {
        setBrands([]);
      },
    }
  );

  return (
    <>
      <section className="integration-section bg-light ptb-120">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                subtitle="Brands"
                title="We Collaborate with Top Brands"
                description="Our collaborations with premier brands drive innovation and success in the market."
                leftAlign
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="text-lg-end mb-5 mb-lg-0" data-aos="fade-left">
                <Link to="/brands" className="btn btn-primary">
                  View All Brands
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="integration-wrapper position-relative w-100">
                {gettingBrands ? (
                  <Skeleton active />
                ) : brands.length > 0 ? (
                  <ul className="integration-list list-unstyled mb-0">
                    {brands.map((one, i) => {
                      return (
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          key={`one-brand-home-${i}`}
                          data-aos="fade-up"
                          data-aos-delay="50"
                          onClick={() => {
                            navigate(`/shop/?brandId=${one._id}`);
                          }}
                        >
                          <div className="single-integration">
                            <img
                              src={one.image}
                              alt="integration"
                              className="img-fluid"
                            />
                            <h6 className="mb-0 mt-4">{one.name}</h6>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <Empty description="No Brands Found" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationTwo;
