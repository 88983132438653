import React, { useState } from "react";
import DetailsTab from "../../components/cyber-product/DetailsTab";
import Product from "../../components/cyber-product/Product";
import RelatedProduct from "../../components/cyber-product/RelatedProduct";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import { productService } from "../../services/productService";
import { Col, Row, Skeleton, Space } from "antd";

const SingleProduct = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const productID = queryParams.get("productID");

  const [product, setProduct] = useState(null);

  const { isFetching: gettingProduct } = productService.getOneProduct(
    "get-one-product",
    productID,
    {
      onSuccess: (response) => {
        if (response.data.success) {
          setProduct(response.data.result);
        } else {
          setProduct(null);
        }
      },
      onError: (error) => {
        setProduct(null);
      },
    }
  );

  return (
    <Layout>
      <Navbar />
      {gettingProduct ? (
        <div className="d-flex justify-content-center">
          <Row
            style={{
              width: "70%",
              gap: 20,
            }}
          >
            <Col span={11}>
              <Skeleton.Image
                style={{
                  height: 400,
                  width: "100%",
                }}
                active={true}
              />
            </Col>
            <Col span={11}>
              <Skeleton
                style={{
                  height: 400,
                  width: "100%",
                }}
                paragraph={{
                  rows: 12,
                }}
                active
              />
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Product product={product} />
          <DetailsTab product={product} />
          {/* <RelatedProduct product={product} /> */}
        </>
      )}

      <FooterOne footerLight />
    </Layout>
  );
};

export default SingleProduct;
