import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import CtaSubscribe from '../../components/cta/CtaSubscribe';
import IntegrationThree from '../../components/integration/IntegrationThree';
import Promo from '../../components/promo/Promo';
import FooterOne from '../../layout/Footer/FooterOne';
import Layout from '../../layout/Layout';
import IntegrationTwo from '../../components/integration/IntegrationTwo';

const Integrations = () => {
  return (
    <Layout>
      <PageMeta title="Eagle Air" />
      <Navbar />
      <PageHeader
        title="We Collaborate with Top Brands"
        desc="Our collaborations with premier brands drive innovation and success in the market."
      />
      <IntegrationTwo />
      {/* <Promo bgWhite /> */}
      <FooterOne />
    </Layout>
  );
};

export default Integrations;
