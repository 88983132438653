import React from "react";
import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";

const HeroFive = () => {
  return (
    <>
      <section
        className="hero-section ptb-120 d-flex align-items-center bg-dark text-white position-relative overflow-hidden"
        // style={{
        //   background: "url('assets/img/page-header-bg.png')",
        //   backgroundPosition: "center",
        // }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 text-center">
              <div className="hero-content-wrap">
                <HeroTitle
                  subtitle="#1 HVACR Parts Supplier In Middle East"
                  title="Mastering Air Control with Premium Parts"
                  desc="Quality components for your peace of mind."
                />

                <div className="action-btns mt-5">
                  <Link to="/shop" className="btn btn-primary me-3">
                    Shop Now
                  </Link>
                  {/* <Link to="/about-us" className="btn btn-outline-light">
                    About Us
                  </Link> */}
                </div>
              </div>
              {/* <div className="row justify-content-lg-start mt-60">
                <h6 className="text-white-70 mb-2">Our Top Clients:</h6>
                <div className="col-4 col-sm-3 my-2 ps-lg-0">
                  <img
                    src="assets/img/clients/client-1.svg"
                    alt="client"
                    className="img-fluid"
                  />
                </div>
                <div className="col-4 col-sm-3 my-2">
                  <img
                    src="assets/img/clients/client-2.svg"
                    alt="client"
                    className="img-fluid"
                  />
                </div>
                <div className="col-4 col-sm-3 my-2">
                  <img
                    src="assets/img/clients/client-3.svg"
                    alt="client"
                    className="img-fluid"
                  />
                </div>
              </div> */}
            </div>
            {/* <div className="col-lg-6 col-md-8 mt-5 mt-lg-0">
              <div className="animated-img-wrap">
                <ul className="animate-element parallax-element animated-hero-1">
                  <li className="layer" data-depth="0.02">
                    <img
                      src="assets/img/screen/animated-screen-2.svg"
                      alt="shape"
                      className="img-fluid position-absolute type-0"
                    />
                  </li>
                </ul>
                <img
                  src="assets/img/screen/animated-screen-1.svg"
                  alt="shape"
                  className="position-relative img-fluid"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroFive;
