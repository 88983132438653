// Require the Firebase SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
  apiKey: "AIzaSyAKpdSSJYqvX1wB0lNyHWCxfuWgCXNfcQg",
  authDomain: "eagle-air-1d463.firebaseapp.com",
  projectId: "eagle-air-1d463",
  storageBucket: "eagle-air-1d463.appspot.com",
  messagingSenderId: "362100101602",
  appId: "1:362100101602:web:5d6804d39f6456dfeaa9bb",
  measurementId: "G-KGH0TL2VEM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)


