import React from "react";
import Swal from "sweetalert2";
import { userService } from "../../services/userService";
import * as Yup from "yup";
import { useFormik } from "formik";

const ContactFormTwo = () => {
  //Api call to submit contact form
  const { mutate: contactFormSubmission, isLoading: sending } =
    userService.contactFormSubmission({
      onSuccess: (response) => {
        if (response.data.success) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
          });
          formik.handleReset();
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message,
          });
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
      },
    });

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Invalid Email"),
    phoneNumber: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      contactFormSubmission(values);
    },
  });

  return (
    <>
      <section
        className="contact-us-form pt-60 pb-120"
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Talk to Our Sales & Support Team</h2>
                {/* <p>Small Paragraph</p> */}
              </div>
              <form action="#" className="register-form">
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="firstName" className="mb-1">
                      Name <span className="text-danger">*</span>
                    </label>
                    <div className=" mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        required
                        placeholder="Full Name"
                        aria-label="Full Name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={sending}
                        value={formik.values.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <small className="text-danger">
                          {formik.errors.name}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="mb-1">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className=" mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder="Email"
                        aria-label="Email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={sending}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <small className="text-danger">
                          {formik.errors.email}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="phone" className="mb-1">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <div className=" mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        required
                        placeholder="Phone"
                        aria-label="Phone"
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={sending}
                        value={formik.values.phoneNumber}
                      />
                      {formik.errors.phoneNumber &&
                        formik.touched.phoneNumber && (
                          <small className="text-danger">
                            {formik.errors.phoneNumber}
                          </small>
                        )}
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="yourMessage" className="mb-1">
                      Message <span className="text-danger">*</span>
                    </label>
                    <div className=" mb-3">
                      <textarea
                        className="form-control"
                        id="yourMessage"
                        required
                        placeholder="How can we help you?"
                        style={{ height: "120px" }}
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={sending}
                        value={formik.values.message}
                      ></textarea>
                      {formik.errors.message && formik.touched.message && (
                        <small className="text-danger">
                          {formik.errors.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  disabled={sending}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  type="button"
                  className="btn btn-primary mt-4"
                >
                  Get in Touch
                </button>
              </form>
            </div>
            <div className="col-lg-5 col-md-10">
              <div className="contact-us-img">
                <img
                  src="assets/img/contact-us-img-2.svg"
                  alt="contact us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
