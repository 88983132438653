import React, { useState } from "react";
import { Link } from "react-router-dom";
import { userService } from "../../services/userService";
import { signInWithCustomToken, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { auth } from "../../config/firebase";

const SignUpForm = () => {
  const [loading, setLoading] = useState(false);

  const { mutate: createUser, isLoading: creating } = userService.createUser({
    onSuccess: (response) => {
      if (response.data.success == true) {
        signInWithCustomToken(auth, response.data.result.firebaseToken)
          .then(async (userCredential) => {
            const idToken = await userCredential.user.getIdToken();
            localStorage.setItem("token", idToken);
            Swal.fire({
              icon: "success",
              text: response.data.message,
            });
            window.location.href = "/";
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: error.message,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message,
        });
      }
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        text: error.message,
      });
    },
  });

  //Api call to create user with google account
  const { mutate: createUserWithGoogle, isLoading: creatingWithGoogle } =
    userService.createUserWithGoogle({
      onSuccess: (response) => {
        if (response.data.success == true) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
          });
          setLoading(false);
          window.location.href = "/";
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message,
          });
          setLoading(false);
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
        setLoading(false);
      },
    });

  const handleSignInWithGoogle = async () => {
    setLoading(true);
    try {
      // Create a Google provider
      const provider = new GoogleAuthProvider();

      // Sign in with the Google provider
      await signInWithPopup(auth, provider);

      // Access user information if needed
      const user = auth.currentUser;

      // Send the ID token to your server
      const idToken = await user.getIdToken();
      localStorage.setItem("token", idToken);
      const payload = {
        idToken: idToken,
      };
      createUserWithGoogle(payload);
    } catch (error) {
      setLoading(false);
      localStorage.setItem("token", "");
      console.error("Google Sign-In Error:", error);
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string(),
    password: Yup.string().min(6, "Minimum 6 chracters").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      createUser(values);
    },
  });

  return (
    <>
      <div className="price-feature-col pricing-action-info p-5 right-radius bg-light order-0 order-lg-1">
        <Link to="/" className="mb-5 d-block d-xl-none d-lg-none">
          <img
            src="assets/img/logo-color.png"
            alt="logo"
            className="img-fluid"
          />
        </Link>
        <h1 className="h3">Create an Account</h1>
        <p className="text-muted">
          Get started with your free account today. No credit card needed and no
          setup fees.
        </p>

        <form action="#" className="mt-5 register-form">
          <div className="row">
            <div className="col-sm-12">
              <label htmlFor="name" className="mb-1">
                Name <span className="text-danger">*</span>
              </label>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading || creating || creatingWithGoogle}
                />
                {formik.errors.name && formik.touched.name && (
                  <small className="text-danger">{formik.errors.name}</small>
                )}
              </div>
            </div>
            <div className="col-sm-12 ">
              <label htmlFor="email" className="mb-1">
                Email <span className="text-danger">*</span>
              </label>
              <div className=" mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading || creating || creatingWithGoogle}
                />
                {formik.errors.email && formik.touched.email && (
                  <small className="text-danger">{formik.errors.email}</small>
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <label htmlFor="company" className="mb-1">
                Phone Number
              </label>
              <div className=" mb-3">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Phone Number"
                  id="phone"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading || creating || creatingWithGoogle}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <small className="text-danger">
                    {formik.errors.phoneNumber}
                  </small>
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <label htmlFor="password" className="mb-1">
                Password <span className="text-danger">*</span>
              </label>
              <div className=" mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={loading || creating || creatingWithGoogle}
                />
                {formik.errors.password && formik.touched.password && (
                  <small className="text-danger">
                    {formik.errors.password}
                  </small>
                )}
              </div>
            </div>
            {/* <div className='col-12'>
              <div className='form-check d-flex'>
                <input
                  className='form-check-input me-2'
                  type='checkbox'
                  value=''
                  id='flexCheckChecked'
                />
                <label className='form-check-label' htmlFor='flexCheckChecked'>
                  I have read and agree to the{' '}
                  <Link to='#' className='text-decoration-none'>
                    Terms & Conditions
                  </Link>
                </label>
              </div>
            </div> */}
            <div className="col-12">
              <button
                disabled={loading || creating || creatingWithGoogle}
                type="button"
                className="btn btn-primary mt-4 d-block w-100"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="position-relative d-flex align-items-center justify-content-center mt-4 py-4">
            <span className="divider-bar"></span>
            <h6 className="position-absolute text-center divider-text bg-light mb-0">
              Or
            </h6>
          </div>
          <div className="action-btns">
            <button
            type="button"
              onClick={() => {
                handleSignInWithGoogle();
              }}
              disabled={loading || creating || creatingWithGoogle}
              style={{
                width: "100%",
              }}
              className="btn google-btn mt-4 d-block bg-white shadow-sm d-flex align-items-center text-decoration-none justify-content-center"
            >
              <img
                src="assets/img/google-icon.svg"
                alt="google"
                className="me-3"
              />
              <span>Sign up with Google</span>
            </button>
          </div>
          <p className="text-center text-muted mt-4 mb-0 fw-medium font-monospace">
            Already have an account?{"  "}
            <Link
              style={{
                color: "var(--bs-primary)",
              }}
              to="/login"
              className="text-decoration-none"
            >
              Sign in
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUpForm;
