import React from "react";
import { Link } from "react-router-dom";
import Rating from "../review/Rating";

const ReviewTab = () => {
  return (
    <>
      <div className="price-feature-col pricing-feature-info text-white left-radius p-5 order-1 order-lg-0">
        <Link to="/" className="mb-5 d-none d-xl-block d-lg-block">
          <img
            style={{
              height: 80,
            }}
            src="assets/img/logo-white.png"
            alt="logo"
            className="img-fluid"
          />
        </Link>
        <div className="customer-testimonial-wrap mt-60">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="testimonial-tab-1"
              role="tabpanel"
            >
              <div className="testimonial-tab-content mb-4">
                <div className="mb-2">
                  <Rating />
                </div>
                <blockquote>
                  <h5>Exceptional Service and Quality</h5>
                  I've been consistently impressed with the level of expertise
                  and service provided. Their parts are top-notch, and the team
                  always goes the extra mile to ensure our HVAC systems are
                  running smoothly.
                </blockquote>
                <div className="author-info mt-4">
                  <h6 className="mb-0">Ahmad Al-Farsi</h6>
                  <span>Facilities Manager</span>
                </div>
              </div>
            </div>
          </div>
          <ul
            className="nav testimonial-tab-list mt-5"
            id="nav-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="active"
                href="#testimonial-tab-1"
                data-bs-toggle="tab"
                data-bs-target="#testimonial-tab-1"
                role="tab"
                aria-selected="true"
              >
                <img
                  src="assets/img/testimonial/1.jpg"
                  className="img-fluid rounded-circle"
                  width="60"
                  alt="user"
                />
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="row justify-content-center mt-60">
          <div className="col-12">
            <p>Already trusted by some of the greatest companies</p>
            <ul className="list-unstyled list-inline mb-0">
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-1.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-2.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-3.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-4.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-5.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
              <li className="list-inline-item">
                <img
                  src="assets/img/clients/client-logo-6.svg"
                  width="120"
                  alt="clients logo"
                  className="img-fluid py-3 me-3 customer-logo"
                />
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ReviewTab;
