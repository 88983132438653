import { useMutation, useQuery } from "@tanstack/react-query";
import { apiUrls } from "../constants";
import {
  getRequest,
  postRequest,
  deleteRequestById,
  putRequest,
  postRequestWithFile,
  axiosInstance,
} from "../helpers/api";

export const userService = {
  createUser: (options) => {
    return useMutation((payload) => {
      return postRequest(apiUrls.createUser, payload);
    }, options);
  },
  createUserWithGoogle: (options) => {
    return useMutation((payload) => {
      return postRequest(apiUrls.createUserWithGoogle, payload);
    }, options);
  },
  updateUser: (options) => {
    return useMutation((payload) => {
      return putRequest(apiUrls.updateUser + payload.id, payload.body);
    }, options);
  },
  addEmail: (options) => {
    return useMutation((payload) => {
      return postRequest(apiUrls.addEmail, payload);
    }, options);
  },
  contactFormSubmission: (options) => {
    return useMutation((payload) => {
      return postRequest(apiUrls.contactFormSubmission, payload);
    }, options);
  },
};
