import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../config/firebase";
import Swal from "sweetalert2";
import { APIBASEURL, apiUrls } from "../../constants";
import axios from "axios";

const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required").min(6, "Minimum 6"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const idToken = await user.getIdToken();
          localStorage.setItem("token", idToken);
          window.location.href = "/";
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error.message,
          });
          setLoading(false);
        });
      localStorage.setItem("token", "");
    },
  });

  const handleSignInWithGoogle = async () => {
    setLoading2(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      localStorage.setItem("token", idToken);
      const response = await axios.post(APIBASEURL + apiUrls.signInUser, {
        firebaseToken: idToken,
      });
      if (response.data.success) {
        window.location.href = "/";
        setLoading2(false);
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message,
        });
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      localStorage.setItem("token", "");
      Swal.fire({
        icon: "error",
        text: error.message,
      });
    }
  };

  return (
    <>
      <section
        className="sign-up-in-section bg-dark ptb-60"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8 col-12">
              <Link to="/" className="mb-4 d-block text-center">
                <img
                  style={{
                    height: 80,
                  }}
                  src="assets/img/logo-white.png"
                  alt="logo"
                  className="img-fluid"
                />
              </Link>
              <div className="register-wrap p-5 bg-light shadow rounded-custom">
                <h1 className="h3">Nice to Seeing You Again</h1>
                <p className="text-muted">
                  Please log in to access your account.
                </p>

                <div className="action-btns">
                  <button
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      handleSignInWithGoogle();
                    }}
                    className="btn google-btn bg-white shadow-sm mt-4 d-block d-flex align-items-center text-decoration-none justify-content-center"
                  >
                    <img
                      src="assets/img/google-icon.svg"
                      alt="google"
                      className="me-3"
                    />
                    <span>Connect with Google</span>
                  </button>
                </div>
                <div className="position-relative d-flex align-items-center justify-content-center mt-4 py-4">
                  <span className="divider-bar"></span>
                  <h6 className="position-absolute text-center divider-text bg-light mb-0">
                    Or
                  </h6>
                </div>
                <form action="#" className="mt-4 register-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="mb-1">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className=" mb-3">
                        <input
                          type="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          disabled={loading || loading2}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <small className="text-danger">
                            {formik.errors.email}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="password" className="mb-1">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className=" mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={loading || loading2}
                        />
                        {formik.errors.password && formik.touched.password && (
                          <small className="text-danger">
                            {formik.errors.password}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        disabled={loading || loading2}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        type="button"
                        className="btn btn-primary mt-3 d-block w-100"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <p className="font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0">
                    Don’t have an account?{" "}
                    <Link
                      style={{
                        color: "var(--bs-primary)",
                      }}
                      to="/signup"
                      className="text-decoration-none"
                    >
                      Sign up Today
                    </Link>
                    <br />
                    <Link
                      style={{
                        color: "var(--bs-primary)",
                      }}
                      to="/password-reset"
                      className="text-decoration-none"
                    >
                      Forgot password
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
