import { useMutation, useQuery } from "@tanstack/react-query";
import { apiUrls } from "../constants";
import {
  getRequest,
  postRequest,
  deleteRequestById,
  putRequest,
  postRequestWithFile,
  axiosInstance,
} from "../helpers/api";

export const productService = {
  getAllProducts: (
    key,
    pageNumber,
    pageSize,
    keyword,
    parentCategory,
    childCategory,
    sortOption,
    brand,
    featured,
    isActive,
    options
  ) => {
    return useQuery(
      [
        key,
        pageNumber,
        pageSize,
        keyword,
        parentCategory,
        childCategory,
        sortOption,
        brand,
        featured,
        isActive,
      ],
      () => {
        return getRequest(
          apiUrls.getAllProducts +
            `?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}` +
            `&parentCategory=${parentCategory}&childCategory=${childCategory}&sortOption=${sortOption}` +
            `&brand=${brand}&featured=${featured}&isActive=${isActive}`
        );
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        ...options,
      }
    );
  },
  getAllProductsWithFilter: (
    key,
    parentCategory,
    childCategory,
    sortOption,
    options
  ) => {
    return useQuery(
      [key, parentCategory, childCategory, sortOption],
      () => {
        return getRequest(
          apiUrls.getAllProducts +
            `?parentCategory=${parentCategory}&childCategory=${childCategory}&sortOption=${sortOption}`
        );
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        ...options,
      }
    );
  },
  getOneProduct: (key, productId, options) => {
    return useQuery(
      [key, productId],
      () => {
        return getRequest(apiUrls.getOneProduct + productId);
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: productId != null || productId != undefined,
        ...options,
      }
    );
  },
  addProductReview: (options) => {
    return useMutation(
      (payload) => {
        return postRequest(apiUrls.addProductReview + payload.id, payload.body);
      },
      {
        ...options,
      }
    );
  },
  getRealatedProducts: (key, productId, options) => {
    return useQuery(
      [key, productId],
      () => {
        return getRequest(apiUrls.getRealatedProducts + productId);
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: productId != null || productId != undefined,
        ...options,
      }
    );
  },
};
