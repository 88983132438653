import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitle from "../common/SectionTitle";
import { categoryService } from "../../services/categoryService";
import { Empty, Skeleton } from "antd";

const FeatureTwo = ({ cardDark }) => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const { isFetching: gettingCategories } = categoryService.getAllCategory(
    "get-all-categories",
    {
      onSuccess: (response) => {
        if (response.data.success) {
          setCategories(response.data.result);
        } else {
          setCategories([]);
        }
      },
      onError: (error) => {
        setCategories([]);
      },
    }
  );

  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? "bg-dark" : "bg-light"
        }`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              {cardDark ? (
                <SectionTitle
                  subtitle="Services"
                  title="Best Services Grow Your Business Value"
                  description="Globally actualize cost effective with resource maximizing
                  leadership skills."
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle="Categories"
                  title="Browse Our Comprehensive Selection of HVACR Parts"
                  description="Find Everything You Need to Keep Your System Running Smoothly"
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className="row">
            {gettingCategories ? (
              <Skeleton active />
            ) : (
              <div className="col-12">
                <div className="feature-grid">
                  {categories.length > 0 ? (
                    categories.map((one, i) => {
                      if (one.subCategories.length > 0) {
                        return (
                          <>
                            {one.subCategories.map((oneSubCategory, index) => {
                              return (
                                <div
                                  className={`feature-card shadow-sm rounded-custom p-5 ${
                                    cardDark
                                      ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                                      : "bg-white"
                                  }`}
                                  data-aos="fade-up"
                                  data-aos-delay="50"
                                >
                                  <div
                                    style={{
                                      backgroundImage: `url(${oneSubCategory.image})`,
                                    }}
                                    className="one-image-div"
                                  ></div>
                                  <div
                                    style={{
                                      marginTop: 50,
                                    }}
                                    className="feature-content text-center"
                                  >
                                    <h3 className="h5">
                                      {oneSubCategory.name}
                                    </h3>
                                    <p className="mb-0">
                                      {oneSubCategory.description}
                                    </p>
                                    <Link
                                      to={`/shop?categoryId=${one._id}&subCategoryId=${oneSubCategory._id}`}
                                      className="link-with-icon text-decoration-none mt-3"
                                    >
                                      View Products{" "}
                                      <i className="far fa-arrow-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      } else {
                        return (
                          <div
                            className={`feature-card shadow-sm rounded-custom p-5 ${
                              cardDark
                                ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                                : "bg-white"
                            }`}
                            data-aos="fade-up"
                            data-aos-delay="50"
                          >
                            <div
                              style={{
                                backgroundImage: `url(${one.image})`,
                              }}
                              className="one-image-div"
                            ></div>
                            <div
                              style={{
                                marginTop: 50,
                              }}
                              className="feature-content text-center"
                            >
                              <h3 className="h5">{one.name}</h3>
                              <p className="mb-0">{one.description}</p>
                              <Link
                                to={`/shop?categoryId=${one._id}`}
                                className="link-with-icon text-decoration-none mt-3"
                              >
                                View Products{" "}
                                <i className="far fa-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <Empty description="No Categories Found" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
