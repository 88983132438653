import React, { useEffect, useState } from "react";
import PageHeader from "../../components/common/PageHeader";
import CtaTwo from "../../components/cta/CtaTwo";
import FeatureTwo from "../../components/features/FeatureTwo";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import CyberStore from "../../components/others/CyberStore";
import { Link } from "react-router-dom";
import { Col, Empty, Input, Row, Select, Skeleton } from "antd";
import { categoryService } from "../../services/categoryService";
import { productService } from "../../services/productService";
import { brandService } from "../../services/brandService";

const Shop = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const categoryId = urlParams.get("categoryId");
  const subCategoryId = urlParams.get("subCategoryId");
  const brandId = urlParams.get("brandId");
  const keywordText = urlParams.get("keywordText");
  const sortBy = urlParams.get("sortBy");
  const featured = urlParams.get("featured");
  const [products, setProducts] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [brandValue, setBrandValue] = useState("");
  const sortOptions = [
    { label: "Top Rated", value: "topRated" },
    { label: "Price High to Low", value: "priceHighToLow" },
    { label: "Price Low to High", value: "priceLowToHigh" },
    { label: "Oldest", value: "oldest" },
    { label: "Latest", value: "latest" },
  ];
  const [sortOptionValue, setSortOptionValue] = useState("latest");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const [keyword, setKeyword] = useState("");
  const [showFeatured, setShowFeatured] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const { Search } = Input;

  useEffect(() => {
    if (categoryId) {
      setCategoryValue(categoryId);
    } else {
      setCategoryValue("");
    }
  }, [categoryId]);

  useEffect(() => {
    if (subCategoryId) {
      setSubCategoryValue(subCategoryId);
    } else {
      setSubCategoryValue("");
    }
  }, [subCategoryId]);

  useEffect(() => {
    if (brandId) {
      setBrandValue(brandId);
    } else {
      setBrandValue("");
    }
  }, [brandId]);

  useEffect(() => {
    if (keywordText) {
      setKeyword(keywordText);
      setSearchInputValue(keywordText);
    } else {
      setKeyword("");
      setSearchInputValue("");
    }
  }, [keywordText]);

  useEffect(() => {
    if (sortBy) {
      setSortOptionValue(sortBy);
    } else {
      setSortOptionValue("latest");
    }
  }, [sortBy]);

  useEffect(() => {
    if (featured) {
      setShowFeatured(true);
    } else {
      setShowFeatured("");
    }
  }, [featured]);

  //Api call to get brands
  const { isFetching: gettingBrands } = brandService.getAllBrands(
    "get-all-brands",
    {
      onSuccess: (response) => {
        if (response.data.success) {
          const options = response.data.result.map((one) => {
            return {
              label: one.name,
              value: one._id,
            };
          });
          setBrandOptions(options);
        } else {
          setBrandOptions([]);
        }
      },
      onError: (error) => {
        setBrandOptions([]);
      },
    }
  );

  //Api call to get categories
  const { isFetching: gettingCategories } = categoryService.getAllCategory(
    "get-all-categories",
    {
      onSuccess: (response) => {
        if (response.data.success) {
          const options = response.data.result.map((one) => {
            return {
              label: one.name,
              value: one._id,
            };
          });
          setCategoryOptions(options);
        } else {
          setCategoryOptions([]);
        }
      },
      onError: (error) => {
        setCategoryOptions([]);
      },
    }
  );

  //Api call to get sub category by category
  const { isFetching: gettingSubCategories } =
    categoryService.getSubCategoryByCategory(
      "get-sub-categories",
      categoryValue,
      {
        onSuccess: (response) => {
          if (response.data.success) {
            const options = response.data.result.map((one) => {
              return {
                label: one.name,
                value: one._id,
              };
            });
            setSubCategoryOptions(options);
          } else {
            setSubCategoryOptions([]);
          }
        },
        onError: (error) => {
          setSubCategoryOptions([]);
        },
      }
    );

  //Api call to get Products
  const { isFetching: gettingProducts } = productService.getAllProducts(
    "get-all-products",
    pageNumber,
    pageSize,
    keyword,
    categoryValue,
    subCategoryValue,
    sortOptionValue,
    brandValue,
    showFeatured,
    true,
    {
      onSuccess: (response) => {
        if (response.data.success) {
          setProducts(response.data.result.docs);
          setTotalRecords(response.data.result.totalDocs);
        } else {
          setProducts([]);
          setTotalRecords(0);
        }
      },
      onError: (error) => {
        setProducts([]);
        setTotalRecords(0);
      },
    }
  );

  return (
    <Layout>
      <Navbar />
      <PageHeader
        title="Our Online Store"
        desc="Find Everything You Need to Keep Your System Running Smoothly"
      />
      <div
        style={{
          marginTop: 70,
          marginBottom: 70,
        }}
        className="container"
      >
        <div>
          <h2
            style={{
              marginBottom: 20,
            }}
            className="fw-bold"
          >
            Filters
          </h2>
          <div
            style={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              rowGap: "10px",
              marginBottom: 30,
            }}
            className="d-flex align-items-center"
          >
            <div>
              <Search
                id="searchinput"
                size="large"
                onSearch={(value) => {
                  setKeyword(value);
                  sendEventToFacebook(
                    Enums.facebookPixelEvents.Search,
                    user?.email ? user.email : "",
                    user?.phoneNumber ? user.phoneNumber : ""
                  );
                }}
                onChange={(event) => {
                  if (event.target.value == "") {
                    setKeyword("");
                    setSearchInputValue("");
                  } else {
                    setSearchInputValue(event.target.value);
                  }
                }}
                value={searchInputValue}
                allowClear={true}
                placeholder="Search Products"
              />
            </div>

            <div>
              <Select
                size="large"
                allowClear
                style={{
                  minWidth: 150,
                }}
                options={categoryOptions}
                showSearch
                onChange={(value) => {
                  setCategoryValue(value ? value : "");
                  setSubCategoryValue("");
                }}
                value={!categoryValue ? "Select Category" : categoryValue}
                loading={gettingCategories}
              />
            </div>

            <div>
              <Select
                size="large"
                allowClear
                style={{
                  minWidth: 150,
                }}
                options={subCategoryOptions}
                showSearch
                placeholder="Select Sub Category"
                onChange={(value) => {
                  setSubCategoryValue(value ? value : "");
                }}
                value={
                  !subCategoryValue ? "Select Sub Category" : subCategoryValue
                }
                loading={gettingSubCategories}
              />
            </div>

            <div>
              <Select
                size="large"
                allowClear
                style={{
                  minWidth: 150,
                }}
                options={brandOptions}
                showSearch
                placeholder="Select Brand"
                onChange={(value) => {
                  setBrandValue(value ? value : "");
                }}
                value={!brandValue ? "Select Brand" : brandValue}
                loading={gettingBrands}
              />
            </div>

            <div>
              <Select
                size="large"
                allowClear
                style={{
                  minWidth: 150,
                }}
                options={sortOptions}
                showSearch
                placeholder="Sort By"
                onChange={(value) => {
                  setSortOptionValue(value ? value : "");
                }}
                value={sortOptionValue}
              />
            </div>
          </div>
        </div>

        {gettingProducts ? (
          <Row gutter={[20, 20]}>
            {Array.from({ length: 8 }).map((one, i) => {
              return (
                <Col span={6}>
                  <Skeleton.Image
                    active
                    style={{
                      width: "100%",
                      height: 250,
                    }}
                  />
                  <Skeleton
                    style={{
                      marginTop: 10,
                    }}
                    paragraph={false}
                    active
                    avatar={false}
                  />
                </Col>
              );
            })}
          </Row>
        ) : products.length > 0 ? (
          <div className="row">
            {products.map((one, i) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="single-product position-relative mb-30">
                    <div className="product-img">
                      <img
                        src={one.images[0]}
                        alt={`${one.name} Eagle Air`}
                        className="img-fluid"
                      />
                      {one.discount > 0 ? (
                        <span className="bg-primary text-white px-2 rounded sale-badge">
                          Sale!
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="product-cart-wishlist-icon">
                      <div className="cart">
                        <i className="far fa-cart-shopping"></i>
                      </div>
                      {/* <div className="heart">
                        <i className="far fa-heart"></i>
                      </div> */}
                    </div>
                    <div className="bg-white text-center shadow-sm py-4 product-info">
                      <h6>
                        <Link
                          to={`/product-details?productID=${one._id}`}
                          className="text-decoration-none"
                        >
                          {one.name}
                        </Link>
                      </h6>
                      <span className="fw-bold">{`$ ${one.priceAfterDiscount}`}</span>
                      <span className="text-decoration-line-through">
                        {"  "}
                        {`$ ${one.price}`}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Empty description="No Products Found" />
        )}
      </div>
      <FooterOne />
    </Layout>
  );
};

export default Shop;
