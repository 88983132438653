import React from "react";
import AboutPageHero from "../components/about/AboutPageHero";
import OurStory from "../components/about/OurStory";
import PageMeta from "../components/common/PageMeta";
import CtaTwo from "../components/cta/CtaTwo";
import FeatureImgThree from "../components/features/FeatureImgThree";
import Team from "../components/team/Team";
import TestimonialTwo from "../components/testimonial/TestimonialTwo";
import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../components/common/PageHeader";

const About = () => {
  return (
    <Layout>
      <PageMeta title="Eagle Air" />
      <Navbar classOption="navbar-light" />
      <PageHeader
        title="Leading the Charge in HVACR Solutions"
        desc="With a focus on enhancing efficiency and performance, we offer a comprehensive range of HVACR parts that meet rigorous quality standards"
      />
      <OurStory />
      {/* <FeatureImgThree />
      <Team /> */}
      {/* <TestimonialTwo /> */}
      {/* <CtaTwo /> */}
      <FooterOne />
    </Layout>
  );
};

export default About;
